import React from "react"
import { Router } from "@reach/router"
import Checkout from '../components/Checkout'
import Layout from '../components/Layout'
import Helmet from "react-helmet"
import Breadcrumb from "../components/Breadcrumb"
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next'
import favicon from "../../static/favicon.svg"
import {useSiteMetadata} from "../hooks/use-site-metadata";


export default function CheckoutPage() {
  const { t } = useTranslation("Index");
  const { title } = useSiteMetadata();
  const { language } = React.useContext(I18nextContext);

  return (
    <Layout>
      <Helmet>
        <html lang={language}/>
        <title>{t(title)}</title>
        <link rel="icon" href={favicon}/>
        <meta name="title" content={title}/>
        <meta name="og:title" content={title}/>
      </Helmet>
      <Router basepath={`/${language}/checkout/`}>
        <Breadcrumb path="/" step={'compra'}/>
      </Router>
      <Router basepath={`/${language}/checkout/`}>
        <Checkout path="/"></Checkout>
      </Router>
    </Layout>
  )
}
