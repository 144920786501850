import React, { Component } from "react"
import { navigate } from "@reach/router"
import { StaticQuery, graphql } from "gatsby"
import { withTranslation, I18nextContext } from "gatsby-plugin-react-i18next"
import UIkit from "uikit"
import VolcanoCheckoutWidget from "@volcanoteide/vte-components/dist/CheckoutWidget/VolcanoCheckoutWidget"
import {onAddPaymentInfoCartDL, onAddShippingInfoDL, onRemoveFromCartDL} from "./lib/dataLayer";
import _uniq from "lodash/uniq";
import VolcanoApi from "@volcanoteide/volcanoteide-api-client"

class Checkout extends Component {
  static contextType = I18nextContext

  componentDidMount() {
    if (typeof window !== `undefined`) {
      UIkit.container = ".uk-scope"
    }
  }

  render() {
      const experiences = []
      const addShippingInfoDL = (apiConfig, cart) => {
          const client = new VolcanoApi({
              ...apiConfig,
              locale: this.context.language
          })

          const experienceIds = _uniq(cart.line_items.map((booking) => booking.product.experience.id))
          const promises = experienceIds.map((experienceId) => client.experience.getExperience(experienceId))

          Promise.all(promises).then((values) => {
              values.forEach((experience) => {
                  experiences[experience.id] = experience
              })

              onAddShippingInfoDL(cart, experiences);
          });
      };

    return (
      <div className={`container checkout mt-5 mt-md-0`}>
        <StaticQuery
          query={graphql`
            query CheckoutDataQuery {
              site {
                siteMetadata {
                  volcanoTeideAPIConfig {
                    protocol
                    host
                    port
                    strictSSL
                    timeout
                    site_key
                  }
                  experiences
                }
              }
              allExperience {
                nodes {
                  experienceId
                  path
                  lang
                }
              }
            }
          `}
          render={data => {
            const experience = data.allExperience.nodes.find(
              node =>
                node.experienceId === data.site.siteMetadata.experiences[0] &&
                node.lang === this.context.language
            )
            const pathForEmptyCart = experience ? experience.path : "/"
            return (
              <VolcanoCheckoutWidget
                apiConfig={data.site.siteMetadata.volcanoTeideAPIConfig}
                locale={this.context.language}
                onEmptyCart={function () {
                  navigate(pathForEmptyCart)
                }}
                allowInvoice={false}
                trackingCallbacks= {{
                  onLoadCallBack: (cart) => {
                    addShippingInfoDL(data.site.siteMetadata.volcanoTeideAPIConfig, cart);
                  },
                  onSubmitCallBack: (cart) => {
                    window.addDataLayer({
                      event: "checkout_form",
                      form_submit_result:  "enviado correctamente"
                    })
                    onAddPaymentInfoCartDL(cart, experiences);
                  },
                  onRemoveItemCallBack: (item) => {
                    onRemoveFromCartDL(item, experiences);
                  },
                  onErrorCallBack: (error) => {
                    window.addDataLayer({
                      event: "checkout_form",
                      form_error: error,
                      form_submit_result:  "fallo en el envio"
                    })
                  }
                }}

              />
            )
          }}
        />
      </div>
    )
  }
}

export default withTranslation("Checkout")(Checkout)
